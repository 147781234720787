@font-face {
  font-family: sl_pixlregular;
  src: url("sl_pixl_regular_v1-webfont.ee8fce41.woff2") format("woff2"), url("sl_pixl_regular_v1-webfont.c0d667ef.woff") format("woff"), url("sl_pixl_regular_v1-webfont.9d0db5d3.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: pt_sansbold;
  src: url("ptsans-bold-webfont.93a7d20f.woff2") format("woff2"), url("ptsans-bold-webfont.0fccdf57.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: pt_sansbold_italic;
  src: url("ptsans-bolditalic-webfont.4f73b118.woff2") format("woff2"), url("ptsans-bolditalic-webfont.0571e7be.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: pt_sansitalic;
  src: url("ptsans-italic-webfont.ddd27186.woff2") format("woff2"), url("ptsans-italic-webfont.10a48260.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: pt_sansregular;
  src: url("ptsans-regular-webfont.d8b9cfb3.woff2") format("woff2"), url("ptsans-regular-webfont.754d907b.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/*# sourceMappingURL=index.a169d6a8.css.map */
